import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import SEO from '../components/SEO';

const Title = styled.h1`
  color: red;
`;

const About = () => (
  <Layout>
    <SEO title={`About`} />
    <Title>About Page</Title>
  </Layout>
);

export default About;
